@import url("https://fonts.googleapis.com/css?family=Roboto+Mono:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");

.gradient {
  width: 100vw;
  height: 250px;
  color: #fff;
  position: relative;
  margin-bottom: 50px;
  background: linear-gradient(
    -45deg,
    #051937,
    #004d7a,
    #008793,
    #00bf72,
    #a8eb12
  );
  background-size: 300% 300%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}

p {
  font-family: "Roboto Mono", sans-serif;
  font-weight: 400;
}

h2 {
  font-family: "Roboto Mono", sans-serif;
  font-weight: 400;
}

.fill {
  height: 100%;
}

h1,
h6 {
  font-weight: normal;
  font-size: 70px;
  text-align: center;
}

img.type {
  -webkit-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  margin-bottom: 10px;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
